.main {
  width: 100%;
  height: 100%;
}

.navbar {
  box-shadow: 2px 3px 5px -2px white;
  height: 70px;
  background-color: white;

}

.navbar-brand,
.navbar-nav .nav-link {
  color: black;
  font-size: 20px;
  margin-left: 20px;
  padding-left: 15px;


}

.navbar-brand img {
  height: 60px;
}

.navbar-brand:hover,
.navbar-nav .nav-link:hover {
  color: darkorange;

}


.btn {
  margin-right: 20px;
  color: black;

}

.btn-outline-success {
  color: black;

}

.head {
  width: 100%;
  height: 50px;
  display: flex;
}

.head2 {
  width: 33.3%;
  height: 50px;
}

.head2 p {
  margin-left: 15px;
  font-size: 14px;
  padding-top: 05px;
}

.head2:hover p {
  color: red;
}



.carousel-container {
  width: 100%;
}

.carousel-item img {
  height: 440px;
  width: 100%;

  transition: transform 1s ease, opacity 1s ease;
}

.carousel-item-next img,
.carousel-item-prev img {
  transform: scale(1.1);
  opacity: 0.5;
}

.about {
  width: 90%;
  height: 430px;
  margin-left: 50px;
  margin-top: 10px;
  display: flex;
}

.about2 {
  width: 50%;
  height: 430px;

}

.about2 h1 {
  margin-left: 40px;
  font-size: 30px;
  margin-top: 20px;
  color: red;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.about2 p {
  width: 90%;
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  margin-top: 20px;
  color: black;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.about2 button {
  margin-left: 50px;
}

.about2 img {
  width: 100%;
  height: 100%;
  border-radius: 10px;

}

.emergncy {
  width: 100%;
  height: 80px;
  background-color: skyblue;
  margin-top: 15px;
  text-align: center;
  padding-top: 15px;
  font-size: 30px;
}

.tratment {
  width: 100%;
  height: 330px;
  margin-top: 20px;
  display: flex;
}

.treat2 {
  width: 31%;
  height: 100%;
  margin-left: 20px;
  border: 1px solid red;
  border-radius: 16px;
}

.treat2 h1 {
  text-align: center;
  font-size: 27px;
  margin-top: 20px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.treat2 p {
  margin-top: 25px;
  font-size: 18px;
  width: 90%;
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
  font-family: Georgia, 'Times New Roman', Times, serif;

}


.treat2 button {
  margin-left: 20px;
}

.footer {
  width: 100%;
  height: 400px;
  margin-top: 20px;
  background-color: #232b2b;
  display: flex;
}

.foot {
  width: 25%;
  height: 100%;
  color: white;
}

.foot h1 {
  text-align: center;
  font-size: 30px;
  margin-top: 20px;
}




.foot iframe {
  margin-top: 10px;
  width: 95%;
  height: 300px;
  margin-left: 10px;
  border-radius: 10px;
}


.foot p {
  margin-left: 100px;
  color: white;
  text-decoration: none;
  margin-top: 25px;
  font-size: 18px;
}

.best {
  text-decoration: none;
}


.disclamer {
  width: 100%;
  height: 60px;
  background-color: #3b444b;
  color: white;
  text-align: center;
  padding-top: 20px;
}







.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 999;
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #1877F2;
  color: white;
}

.twitter {
  background: #C13584;
  color: white;
}

.google {
  background: #25D366;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #FF0000;
  color: white;
}






.heading {
  width: 100%;
  height: 130px;
  background-color: #55ACEE;
}

.heading h1 {
  text-align: center;
  padding-top: 36px;
  font-size: 30px;
}


.avicenna {
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: flex;
}

.avicenna2 {
  width: 47%;
  height: 100%;
  margin-left: 30px;
}

.fast {
  width: 90%;
  height: 120px;
  margin-top: 60px;
  margin-left: 50px;
  border-radius: 0px 50px 30px;
  background-color: darkcyan;
}



.fast p {
  color: white;
  font-size: 25px;
  margin-left: 20px;
  padding-top: 15px;
}



@media screen and (min-width: 300px) and (max-width: 600px) {

  .main {
    width: 100%;
    height: 100%;

  }

  .navbar {
    box-shadow: 2px 3px 5px -2px white;
    height: 80px;
    background-color: white;
    width: 100%;
  }

  .navbar-brand,
  .navbar-nav .nav-link {
    color: black;
    font-size: 18px;
    margin-left: 10px;
    padding-left: 15px;
  background-color: white;

  }

  .navbar-brand img {
    height: 60px;
  }

  .navbar-brand:hover,
  .navbar-nav .nav-link:hover {
    color: darkorange;
  }


  .btn {
    margin-right: 20px;
    color: black;
  }

  .btn-outline-success {
    color: black;

  }

  .head {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    display: none;
  }

  .head2 {
    width: 100%;
    height: 30px;
  }

  .head2 p {
    margin-left: 05px;
    font-size: 13px;
    padding-top: 05px;
  }

  .head2:hover p {
    color: red;
  }



  .carousel-container {
    width: 100%;
    z-index: -1;
  }

  .carousel-item img {
    height: 200px;
    width: 100%;
    transition: transform 1s ease, opacity 1s ease;
  }

  .carousel-item-next img,
  .carousel-item-prev img {
    transform: scale(1.1);
    opacity: 0.5;
  }

  .about {
    width: 100%;
    height: auto;
    margin-left: 0px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .about2 {
    width: 100%;
    height: auto;

  }

  .about2 h1 {
    margin-left: 10px;
    font-size: 24px;
    margin-top: 20px;
    color: red;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .about2 p {
    width: 90%;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    margin-top: 20px;
    color: black;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .about2 button {
    margin-left: 50px;
  }

  .about2 img {
    width: 100%;
    height: 100%;
  }

  .emergncy {
    width: 100%;
    height: 80px;
    background-color: skyblue;
    margin-top: 15px;
    text-align: center;
    padding-top: 15px;

  }

  .emergncy h1 {
    font-size: 22px;
  }

  .tratment {
    width: 100%;
    height: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .treat2 {
    width: 100%;
    height: 350px;
    margin-left: 0px;
    border: 1px solid gray;
    border-radius: 16px;
    margin-top: 19px;
  }

  .treat2 h1 {
    text-align: center;
    font-size: 27px;
    margin-top: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .treat2 p {
    margin-top: 25px;
    font-size: 18px;
    width: 90%;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    font-family: Georgia, 'Times New Roman', Times, serif;

  }

  

  .footer {
    width: 100%;
    height: auto;
    margin-top: 20px;
    background-color: #232b2b;
    display: flex;
    flex-direction: column;
  }

  .foot {
    width: 100%;
    height: 100%;
    color: white;
  }

  .foot h1 {
    text-align: center;
    font-size: 30px;
    margin-top: 20px;
  }

  .foot iframe {
    margin-top: 10px;
    width: 95%;
    height: 300px;
    margin-left: 10px;
    border-radius: 10px;
  }


  .foot p {
    margin-left: 100px;
    color: white;
    text-decoration: none;
    margin-top: 25px;
    font-size: 18px;
  }

  .best {
    text-decoration: none;
  }


  .disclamer {
    width: 100%;
    height: 70px;
    background-color: #3b444b;
    color: white;
    text-align: center;
    padding-top: 10px;
  }

  .icon-bar {
    position: fixed;
    top: 66%;
    margin-left: 85%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 999;
  }

  .icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
  }

  .icon-bar a:hover {
    background-color: #000;
  }

  .facebook {
    background: #3B5998;
    color: white;
  }

  .twitter {
    background: #55ACEE;
    color: white;
  }

  .google {
    background: #dd4b39;
    color: white;
  }

  .linkedin {
    background: #007bb5;
    color: white;
  }

  .youtube {
    background: #bb0000;
    color: white;
  }

  
.avicenna {
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.avicenna2 {
  width: 100%;
  height: 100%;
  margin-left: 0px;
  margin-top: 15px;
}

.avicenna2 img {
  width: 100%;
  height: 10%;
}

.fast {
  width: 98%;
  height: 120px;
  margin-top: 30px;
  margin-left: 05px;
  border-radius: 0px 50px 30px;
  background-color: darkcyan;
}



.fast p {
  color: white;
  font-size: 18px;
  margin-left: 10px;
  padding-top: 25px;
}

}




@media screen and (min-width: 601px) and (max-width: 1250px) {

  .main {
    width: 1250px;
    height: 100%;
  }

  .navbar {
    box-shadow: 2px 3px 5px -2px white;
    height: 70px;
    background-color: white;
  }

  .navbar-brand,
  .navbar-nav .nav-link {
    color: black;
    font-size: 18px;
    margin-left: 20px;
    padding-left: 15px;
    background-color: white;

  }

  .navbar-brand img {
    height: 60px;
  }

  .navbar-brand:hover,
  .navbar-nav .nav-link:hover {
    color: darkorange;

  }


  .btn {
    margin-right: 20px;
    color: black;

  }

  .btn-outline-success {
    color: black;

  }

  .head {
    width: 100%;
    height: 40px;
    display: flex;
  }

  .head2 {
    width: 33.3%;
    height: 40px;
  }

  .head2 p {
    margin-left: 02px;
    font-size: 16px;
    padding-top: 05px;
  }

  .head2:hover p {
    color: red;
  }



  .carousel-container {
    width: 100%;
  }

  .carousel-item img {
    height: 440px;
    width: 100%;

    transition: transform 1s ease, opacity 1s ease;
  }

  .carousel-item-next img,
  .carousel-item-prev img {
    transform: scale(1.1);
    opacity: 0.5;
  }

  .about {
    width: 90%;
    height: 430px;
    margin-left: 50px;
    margin-top: 10px;
    display: flex;
  }

  .about2 {
    width: 50%;
    height: 430px;

  }

  .about2 h1 {
    margin-left: 40px;
    font-size: 30px;
    margin-top: 20px;
    color: red;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .about2 p {
    width: 90%;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    margin-top: 20px;
    color: black;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .about2 button {
    margin-left: 50px;
  }

  .about2 img {
    width: 100%;
    height: 100%;
    border-radius: 10px;

  }

  .emergncy {
    width: 100%;
    height: 80px;
    background-color: skyblue;
    margin-top: 15px;
    text-align: center;
    padding-top: 15px;
    font-size: 30px;
  }

  .tratment {
    width: 100%;
    height: 300px;
    margin-top: 20px;
    display: flex;
  }

  .treat2 {
    width: 31%;
    height: 100%;
    margin-left: 20px;
    border: 1px solid red;
    border-radius: 16px;
  }

  .treat2 h1 {
    text-align: center;
    font-size: 27px;
    margin-top: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .treat2 p {
    margin-top: 25px;
    font-size: 18px;
    width: 90%;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    font-family: Georgia, 'Times New Roman', Times, serif;

  }

  .treat2:hover {
    margin-top: -10px;
  }

  .footer {
    width: 100%;
    height: 400px;
    margin-top: 20px;
    background-color: #232b2b;
    display: flex;
  }

  .foot {
    width: 25%;
    height: 100%;
    color: white;
  }

  .foot h1 {
    text-align: center;
    font-size: 30px;
    margin-top: 20px;
  }

  .foot iframe {
    margin-top: 10px;
    width: 95%;
    height: 300px;
    margin-left: 10px;
    border-radius: 10px;
  }


  .foot p {
    margin-left: 100px;
    color: white;
    text-decoration: none;
    margin-top: 25px;
    font-size: 18px;
  }

  .best {
    text-decoration: none;
  }


  .disclamer {
    width: 100%;
    height: 60px;
    background-color: #3b444b;
    color: white;
    text-align: center;
    padding-top: 20px;
  }

  .icon-bar {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 999;
  }

  .icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
  }

  .icon-bar a:hover {
    background-color: #000;
  }

  .facebook {
    background: #1877F2;
    color: white;
  }

  .twitter {
    background: #C13584;
    color: white;
  }

  .google {
    background: #25D366;
    color: white;
  }

  .linkedin {
    background: #007bb5;
    color: white;
  }

  .youtube {
    background: #FF0000;
    color: white;
  }

  .heading {
    width: 100%;
    height: 130px;
    background-color: #55ACEE;
  }

  .heading h1 {
    text-align: center;
    padding-top: 36px;
    font-size: 30px;
  }

  
.avicenna {
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: flex;
}

.avicenna2 {
  width: 47%;
  height: 100%;
  margin-left: 30px;
}

.fast {
  width: 90%;
  height: 120px;
  margin-top: 60px;
  margin-left: 50px;
  border-radius: 0px 50px 30px;
  background-color: darkcyan;
}



.fast p {
  color: white;
  font-size: 25px;
  margin-left: 20px;
  padding-top: 15px;
}
}