/* CSS for gallery container */
.gallery {
  display: flex;
  /* Use flexbox for layout */
  flex-wrap: wrap;
  /* Allow items to wrap to the next line */
  gap: 10px;
  /* Space between items */
  justify-content: center;
  /* Center items horizontally */
  padding: 20px;
  /* Padding around the gallery */
}

/* CSS for each gallery item */
.gal {
  flex: 1 1 calc(33.333% - 20px);
  /* Responsive width and space adjustment */
  box-sizing: border-box;
  /* Include padding and border in element's total width and height */
}


.gal img {
  width: 100%;
  /* Make images responsive to container width */
  height: auto;
  /* Maintain aspect ratio */
  display: block;
  /* Remove bottom space around images */
  border-radius: 5px;
  /* Optional: rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Optional: subtle shadow */
}


@media (max-width: 768px) {
  .gal {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .gal {
    flex: 1 1 calc(100% - 20px);
  }
}

.container {
  margin-top: 50px;
  overflow: auto;
}


.header {
  width: 100%;
  height: 30px;
  background-color: #C13584;
  color: white;
}

.why {
  width: 100%;
  height: 600px;
  margin-top: 20px;
  background-image: url(./component/img/back.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.why h1 {
  text-align: center;
  padding-top: 50px;
  color: white;
}

.whys2 {
  width: 100%;
  height: 470px;
  margin-top: 30px;
  display: flex;
}

.agency {
  width: 48%;
  height: 100%;
  margin-left: 20px;
}

.agency2 {
  width: 100%;
  height: 50%;

}

.agency2 h1 {
  font-size: 30px;
  color: white;
}

.agency2 p {
  color: white;
  font-size: 17px;
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
  width: 90%;

}




@media screen and (min-width: 300px) and (max-width: 600px) {

  .why {
    width: 100%;
    height: auto;
    margin-top: 20px;
    background-image: url(./component/img/back.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .why h1 {
    text-align: center;
    padding-top: 20px;
    color: white;
  }

  .whys2 {
    width: 100%;
    height: auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
   
  }

  .agency {
    width: 100%;
    height: 100%;
    margin-left: 0px;
  }

  .agency2 {
    width: 100%;
    height: 50%;

  }

  .agency2 h1 {
    font-size: 25px;
    color: white;
  }

  .agency2 p {
    color: white;
    font-size: 15px;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    width: 90%;

  }

}


@media screen and (min-width: 601px) and (max-width: 1250px){

  .header {
    width: 100%;
    height: 30px;
    background-color: #C13584;
    color: white;
  }
  
  .why {
    width: 100%;
    height: 600px;
    margin-top: 20px;
    background-image: url(./component/img/back.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .why h1 {
    text-align: center;
    padding-top: 50px;
    color: white;
  }
  
  .whys2 {
    width: 100%;
    height: 470px;
    margin-top: 30px;
    display: flex;
  }
  
  .agency {
    width: 48%;
    height: 100%;
    margin-left: 20px;
  }
  
  .agency2 {
    width: 100%;
    height: 50%;
  
  }
  
  .agency2 h1 {
    font-size: 30px;
    color: white;
  }
  
  .agency2 p {
    color: white;
    font-size: 17px;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  
  }
}


.staff-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  gap: 20px;
}

.doctor-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  max-width: 300px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.doctor-card h2 {
  margin-top: 0;
  font-size: 25px;
}
.doctor-card p {
margin-top: 15px;
}

@media (max-width: 768px) {
  .doctor-card {
    max-width: 100%;
  }
}
